<template>
  <div>
    <div
      class="py-20"
      style="background: linear-gradient(90deg, #667eea 0%, #764ba2 100%)"
    >
      <div class="container mx-auto px-6 text-center">
        <h2 class="text-6xl font-bold mb-2 text-white">
          EssentialHealthInfo
        </h2>
        <h3 class="text-2xl mb-8 text-gray-200">
          Your California Privacy Rights
        </h3>
        <a href="http://app.essentialhealthinfo.com/"><button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
          Go Back 
        </button></a>
      </div>
    </div>
    <div
      class="py-20"
      style="background: white;"
    >
      <div class="container mx-auto  pt-3 pl-6 pr-6 pb-6">
        <h2 class="text-2xl font-bold mb-10 text-black">
          Your California Privacy Rights
        </h2>
        <h3 class="text-1xl mb-2 text-gray-500">
          If you are a California resident, the following additional rights may be available to you.
        </h3>
        <ul class="list-decimal ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>California residents may request information regarding EssentialHealthInfo’s disclosure of personal information (such as your name and contact information) to third parties who wish to contact you or send you promotional information about their products and services directly (as compared to when the third party is acting on our behalf or you have provided your personal information as part of a co-promotion by EssentialHealthInfo and the third party). If you are a California resident and would like to request a copy of information regarding our disclosure to third parties for their direct marketing purposes in the preceding calendar year, please send us a letter at Attention: Legal Department, EssentialHealthInfo, 22912 Pacific Park Drive, Suite 200, Aliso Viejo, CA 92656.</li>
          <li>If you are a California resident under the age of 18 and a registered user of the EssentialHealthInfo services, you may request and obtain removal of content or information you have publicly posted on the EssentialHealthInfo services. To make this request, please email us at eih@essenyialhealthinfo.com and include a detailed description of the specific content or information you would like to remove. Please note that this removal does not ensure complete or comprehensive removal of the content or information posted on the EssentialHealthInfo services by you as there may be circumstances in which the law does not require or allow removal.</li>
          <li>Beginning January 1, 2020, the California Consumer Privacy Act of 2018 (“CCPA”) provides California residents with additional rights as described below. Please note your right to know and right to delete are subject to certain exceptions under the CCPA.</li>
          <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
            <li>Disclosures About Your Personal Information</li>
            <li>We collect the following categories of personal information, as defined in the CCPA, from or about you and may have disclosed this information for a business purpose:</li>
            <ul class="list-decimal ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
              <li>Identifiers, such as your name, IP address, email, and other similar identifiers;</li>
              <li>Personal information categories listed in the California Customer Records provision, such as payment information;</li>
              <li>Characteristics about you, such as age and gender;</li>
              <li>Commercial information, such as the EssentialHealthInfo plan(s) you purchase</li>
              <li>Audio, electronic, visual, or other similar information; and</li>
              <li>Inferenced based on the information we collect from or about you which we use to better understand your preferences and behavior, including to customize Content and advertising for you.</li>
            </ul>
            <li>Right to Know. You have the right to request that we disclose to you the categories and specific pieces of personal information we have collected about you as well as information about how we collect, use, disclose, and “sell” personal information.</li>
            <li>Right to Delete. You have the right to request the deletion of personal information that we collect or maintain about you and your account. Please note that requesting deletion will require the cancellation and deletion of your account which, upon completion, cannot be undone.</li>
            <li>Right to Opt Out of “Sale”. EssentialHealthInfo may disclose certain information about you if you are a registered user of the EssentialHealthInfo services for purposes that may be considered a “sale” under the CCPA. For example, we may disclose information to advertising partners, advertising technology companies, and companies that perform advertising-related services in order to provide you with more relevant advertising tailored to your interests on the EssentialHealthInfo services. This information may include identifiers such as your IP address, advertising identifiers, your email address (in a de-identified or hashed form), age and gender, your internet or other electronic network information such as your interaction with an ad, and geolocation data. We may also disclose to our content programmers information about you, which may help personalize your experience and the content and ads you see on EssentialHealthInfo as well as other platforms and services, as further described in our Privacy Policy. To learn more, including about your opt-out choices,</li>
            <li>How to Exercise Your CCPA Rights</li>
            <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
              <li>We provide these rights, subject to a verifiable consumer request, to account holders regarding personal information collected by us in connection with your account, including any profiles. To submit a request to exercise these rights, log into your account on the websites, go to “Privacy and Settings” in account settings and click on “California Privacy Rights” and follow the instructions. You will be required to verify your request via your EssentialHealthInfo account credentials and/or by other means. You can email us at eih@essenyialhealthinfo.com.</li>
              <li>If you are not a registered user of the EssentialHealthInfo services and do not have EssentialHealthInfo account credentials, please note we have limited information about you. You will be required to verify your request, including by providing your name and the email that you believe we have collected about you. You can email us at eih@essenyialhealthinfo.com.</li>
              <li>You may designate an authorized agent to make a request on your behalf. You can email us at eih@essenyialhealthinfo.com.</li>
              <li>If you choose to exercise any of these rights, you will not receive different prices or quality of services unless permitted by applicable law, including if those differences are reasonably related to your information. For example, if you exercise your right to opt out of “sale”, the ads you view may not be as tailored to your interests. For questions or concerns about how to exercise these rights and choices, please visit our help center.</li>
            </ul>
          </ul>
        </ul>
      </div>
    </div>
  </div>
<!-- About Us -->

<!-- Awards Banner -->

<!-- what coustomers are saying -->
</template>

<script>
import {EventBus} from "@/eventbus";
//import Storage from "@/storage";



export default {
  name: "DysonCordless",
  data() {
    return {

    };
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('app.screenChanged','pages');
    },1);
  },
}
</script>

<style scoped>


</style>

<template>
  <div>
    <div
      class="py-20"
      style="background: linear-gradient(90deg, #667eea 0%, #764ba2 100%)"
    >
      <div class="container mx-auto px-6 text-center">
        <h2 class="text-6xl font-bold mb-2 text-white">
          EssentialHealthInfo
        </h2>
        <h3 class="text-2xl mb-8 text-gray-200">
          Privacy Policy
        </h3>
        <a href="http://app.essentialhealthinfo.com/"><button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
          Go Back 
        </button></a>
      </div>
    </div>
    <div
      class="py-20"
      style="background: white;"
    >
      <div class="container mx-auto  pt-3 pl-6 pr-6 pb-6">
        <h2 class="text-2xl font-bold mb-10 text-black">
          Privacy Policy
        </h2>

        <h3 class="text-1xl mb-8 text-gray-500">
          EssentialHealthInfo, respects your privacy and intends to protect it through compliance with this privacy policy. In this policy EssentialHealthInfo, Inc. is aliased by the terms, "Company" or "We".
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          This policy describes the types of information we may collect from you or that you may provide when you visit our network of sites. You may view the sites below.
        </h3>
        <ul class="list-disc ml-8 text-blue-500 underline list-inside mb-4 bg-rose-200 ...">
          <li><a href="http://app.essentialhealthinfo.com/">EssentialHealthInfo</a></li>
        </ul>

        <h3 class="text-1xl mb-2 text-gray-500">
          This policy applies to information we collect:
        </h3>

        <ul class="list-disc ml-8 text-gray-500 list-inside bg-rose-200 ...">
          <li>On our website(s)</li>
          <li>In email, text, and other electronic messages between you and our website(s);</li>
          <li>Through mobile and desktop applications you may have downloaded, which provides dedicated non-browser-based interaction between you and our website(s);</li>
          <li>When you interact with our advertising and applications on third-party websites and services, if those applications or advertising include links to this policy.;</li>
        </ul>

        <h3 class="text-1xl mb-2 text-gray-500">
          It does not apply to information collected by:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>Us offline or through any other means, including on any other website(s) operated by EssentialHealthInfo, Inc. or any third-party.</li>
          <li>Or any third-party, including through any applications or content (including Advertising and Promotional materials,) that may link to - or be accessible from [or on] their website(s)</li>
        </ul>

        <h3 class="text-1xl mb-2 text-gray-500">
          Please read this policy carefully to understand our policies and practices regarding your information and how we intend to treat it.
        </h3>

        <h3 class="text-1xl mb-2 text-gray-500">
          If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of our website(s) after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
        </h3>

        <h3 class="text-1xl mb-2 text-gray-500">
          We have tried to keep these below terms as simple as possible, however, if you have any questions please contact us at helpessentialhealthinfo.com.
        </h3>

        <h3 class="text-1xl mb-2 text-bold text-black ">
          Children Under The Age Of 18
        </h3>
        <ul class="list-disc text-gray-500  ml-8 list-inside mb-4 bg-rose-200 ...">
          <li>Our website(s) are not intended for children under 18 years of age.</li>
          <li>We do not knowingly collect personal information from children under 18.</li>
          <li>If you are under 18, do not use or provide us with any information on our website(s). Do not use any of their features, make any purchases through our website(s), or use any of the interactive or public comment features of our website(s). Do not provide information such as name, address, telephone number, email address, or any other screen or user name you may use.</li>
          <li>If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information.</li>
          <li>If you believe we might have any information from or about a child under 18, please contact us at websitesessentialhealthinfo.com</li>
        </ul>

        <h3 class="text-1xl mb-2 text-black text-bold underline">
          Information We Collect About You & How We Collect It
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We collect several types of information from and about users of our website(s), including information:
        </h3>
        <ul class="list-disc ml-8 list-inside text-gray-500 mb-2 bg-rose-200 ...">
          <li>by which you may be personally identified, such as name, date of birth, gender, postal address, e-mail address, telephone number, any other identifier by which you may be contacted online or offline or [ANY OTHER INFORMATION THE WEBSITE COLLECTS THAT IS PERSONAL OR PERSONALLY IDENTIFIABLE INFORMATION] ("personal information");</li>
          <li>that is about you but individually does not identify you, such as your hobbies and interests, the mobile network you use, your gas, electricity and broadband provider; and</li>
          <li>about your internet connection, the equipment you use to access our Website and usage details.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-gray-500">
          We collect this information:
        </h3>
        <ul class="list-disc list-inside text-gray-500 mb-3 bg-rose-200 ...">
          <li>directly from you when you provide it to us;</li>
          <li>automatically as you navigate through the site where information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons and other tracking technologies; and</li>
          <li>from third parties such as our business partners.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Information You Provide Us
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We may collect personal information about you in several ways including information you provide us in the following ways:
        </h3>
        <ul class="list-disc ml-8 list-inside text-gray-500 mb-3 bg-rose-200 ...">
          <h3 class="text-1xl mb-2 text-black text-bold">
            Participating in sweepstakes, contests, offers or other services on our website(s)
          </h3>
          <li>We may ask you for information, such as your name, date of birth, gender, email and postal address and telephone numbers.</li>
          <li>In order for us to tailor our offers to you we may also ask for additional information such as hobbies and interests, the mobile network you use, your gas, electricity and broadband provider, holiday interests and gaming providers</li>
          <h3 class="text-1xl mb-2 text-black text-bold">
            Participating in sweepstakes, competitions, offers or other services on another organization’s website(s)
          </h3>
          <li>We place advertisements on Websites belonging to other organizations. If you click on to one of our advertisements you will be directed to our Website and in order to participate you will be asked for information such as your name, date of birth, email and postal addresses and telephone numbers.</li>
          <h3 class="text-1xl mb-2 text-black text-bold">
            Registering with our website(s)
          </h3>
          <li>If you register with our Website, we will ask you for your name, date of birth, gender, email and postal address, telephone numbers.</li>
          <h3 class="text-1xl mb-2 text-black text-bold">
            Contacting us via our website(s), direct mail, email or contact number.
          </h3>
          <li>If you contact us via our website(s), send us direct mail, or email and provide us with your name and contact details; We will use these details to investigate your query and contact you to deal with your request.</li>
        </ul>
        <h3 class="text-1xl mt-9 mb-4 text-black text-bold underline">
          The information we collect on or through our website(s) may also include the following:
        </h3>
        <ul class="list-disc ml-8 list-inside  text-gray-500 mb-3 bg-rose-200 ...">
          <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
          <li>Details of transactions you carry out through our website(s) and of the fulfilment of your orders. You may be required to provide financial information before placing an order through our website(s).</li>
          <li>Your search queries on our website(s).</li>
        </ul>
        <h3 class="text-1xl mb-2 text-gray-500">
          You also may provide information to be published or displayed (hereinafter, "posted") on public areas of the Website, or transmitted to other users of the website(s) or third parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk. Although [we limit access to certain pages/you may set certain privacy settings for such information by logging into your account profile], please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Information We Collect Through Automatic Data Collection Technologies
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          As you navigate through and interact with our website(s), our website(s) collects information automatically using automatic data collection technologies to collect certain information about your equipment, browsing actions and patterns about the services that you use and how you use them (for example, when you visit a website that uses our advertising or view one of our advertisements) including:
        </h3>
        <ul class="list-disc ml-8 list-inside text-gray-500 mb-3 bg-rose-200 ...">
          <li>Technical Information Including information about your computer and internet connection, including the Internet Protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</li>
          <li>Information About Your Visit To Our Website Including information about your computer and internet connection, including the Internet Protocol (IP) address used to connect your computer to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform.</li>
          <li>[We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).]</li>
        </ul>
        <h3 class="text-1xl mb-2 text-gray-500">
          The information we collect automatically [is statistical data and does not/may] include personal information [, but/or] we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:
        </h3>
        <ul class="list-disc ml-8 list-inside  text-gray-500 mb-3 bg-rose-200 ...">
          <li>Estimate our audience size and usage patterns;</li>
          <li>Store information about your preferences, allowing us to customize our Website according to your individual interests;</li>
          <li>Speed up your searches; and</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          The technologies we use for this automatic data collection may include:
        </h3>
        <ul class="list-disc ml-8 list-inside text-gray-500 mb-3 bg-rose-200 ...">
          <li>Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</li>
          <li>Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.</li>
          <li>Web Beacons. Pages of our the Website [and our e-mails] may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (e.g., recording the popularity of certain website content and verifying system and server integrity).</li>
          <li class="text-black text-bold">
            [We do not collect personal information automatically, but we may tie this information to personal information about you that we collect from other sources or you provide to us.]
          </li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Third-Party Use Of Cookies and Other Tracking Technologies
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          Some content or applications, including advertisements, on our website(s) are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.

          We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, please contact the responsible provider directly. If you are unfamiliar with the responsible provider, we would be happy to fulfil or redirect your request. Please write us below.
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Please Include:
        </h3>
        <ul class="  ml-8 list-inside mb-3  text-gray-500 bg-rose-200 ...">
          <li>EssentialHealthInfo, Inc.</li>
          <li>Third-Party Advertising Inquiry</li>
          <li>23 Veneto Ln.,</li>
          <li>Aliso Viejo,</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Information we collection from other sources.
        </h3>
        <ul class="list-disc text-gray-500  ml-8 list-inside mb-3 bg-rose-200 ...">
          <li>Your full name.</li>
          <li>Your email address you used to associate with an end-user account</li>
          <li>The URL where you signed up</li>
          <li>The estimated time/date you signed up</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Information we collection from other sources.
        </h3>
        <ul class="list-disc text-gray-500 ml-8 list-inside mb-3 bg-rose-200 ...">
          <li>We may receive information about you if you use any of the other websites we operate or the other services we provide. In this case we will have informed you when we collected that data that it may be shared internally and combined with data collected on any of our other website(s).</li>
          <li>We work closely with third parties (including, for example, business partners, sub-contractors in technical, payment and delivery services, advertising networks, data analytics providers, search information providers and credit reference agencies) and may receive information about you from them.</li>
          <li>We may combine information you provide to us with information we collect about you (e.g., to check your details against our suppression list to make sure that you have not previously requested not to receive information from us).</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          How We Use Your Information
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We use the information that we collect about you or that you provide to us, including any personal information:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>to present our website(s) and their contents to you;</li>
          <li>to provide you with the information, products and services that you request from us;</li>
          <li>to carry out our obligations arising from any contracts entered into between you and us;</li>
          <li>to notify you about changes to our website(s) or service;</li>
          <li>to help us develop our website(s) to enhance your experience;</li>
          <li>to gain your feedback about your experience of our website(s) and services;</li>
          <li>to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purpose</li>
          <li>as part of our efforts to keep our site safe and secure;</li>
          <li>to fulfill any other purpose for which you provide it;</li>
          <li>[to provide you with notices about your [account/subscription], including expiration and renewal notices];</li>
          <li>in any other way we may describe when you provide the information; and</li>
        </ul>
        <h3 class="text-1xl mb-2 text-gary-400">
          We may also use your information to contact you about goods and services that may be of interest to you. If you do not want us to use your information in this way, please [check the relevant box located on the form on which we collect your data (the [order form/registration form])].
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.]
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Our Marketing
        </h3>
        <h3 class="text-1xl mb-2 text-gary-400">
          We provide you with information about:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>Products;</li>
          <li>Services</li>
          <li>Special Offers</li>
          <li>Competitions</li>
          <li>Other news that we feel will interst you</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black">
          This may include information on products in different industries such as the:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>Finance</li>
          <li>Insurance</li>
          <li>Travel</li>
          <li>Retail </li>
          <li>Health & Wellness</li>
          <li>Consumer Electronics</li>
          <li>Cleaning Products</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Depending on what contact information you have given to us, we may contact you via:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>Email </li>
          <li>Direct Mail</li>
          <li>Phone Calls</li>
          <li>Text Messages</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          We will only do this where you have consented to receiving such information from us.
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          If you do not want us to use your data in this way, or to pass your details on to third parties for marketing purposes - please unsubscribe.
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>You can leave our website(s) at any time and visit this link. </li>
          <li>EssentialHealthInfo, Inc. will cease sharing your details with participating data partners for marketing purposes.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          If you follow one of the two aforementioned methods to unsubscribe:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>You'll be placed in a suppression file and you will not receive any further marketing correspondence from EssentialHealthInfo, Inc.;</li>
          <li>Email websitesessentialhealthinfo.com and share your email address you've used to sign up across our network of websites.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Please however note that there may be a brief period of time between your unsubscribe request and suppression to allow our systems to update.
          If you no longer wish to receive information from participating data partners you will need to request this from them directly.
          Please note that EssentialHealthInfo, Inc. may still need to retain your details for use for the non-direct marketing purposes.
          For useful information about data protection you can visit:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>The Direct Marketing Association</li>
          <li>The Information Commissioner</li>
          <li>The Citizens Advice Bureau</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Information Sharing
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 text-bold">
          Specific offers, competitions, or services:
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We may share your information with a specific organization where you have opted to participate in an offer, competition or service relating to them. They may contact you.
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Special offers, daily deals and other services:
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We may share your information with carefully selected third parties where they are offering products or services that we believe will interest you.
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Adverts on third party websites:            
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We may place adverts on the websites of third party companies. We do not disclose your personal information to our advertisers, but we may provide them with non-identifiable information (For example, 500 males under the age of 30 have clicked on their advertisement on one day).            
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          Third-party privacy policies:         
        </h3>
        <h3 class="text-1xl mb-2 text-black text-bold">
          Our website(s) may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.         
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 text-bold">
          We may share your information with carefully selected third-parties including:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>Marketing partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</li>
          <li>Analytics and search engine providers that assist us in the improvement and optimisation of our site.</li>
          <h3 class="text-1xl mb-2 text-gray-500 text-bold">
            We may also disclose your personal information to third parties including:
          </h3>
          <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the assets of EssentialHealthInfo, Inc., whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which case the personal information that we hold will be part of the transferred assets;</li>
          <li>If we are required by law, court order or legal process or in order to enforce or apply our terms & conditions and other agreements, including for billing and collection purposes;</li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of EssentialHealthInfo, Inc., our customers, or others including exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
        </ul>
        
        <h3 class="text-1xl mb-2 text-black text-bold">
          Choices About How We Use And Disclose Your Information
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:        
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-3 bg-rose-200 ...">
          <li>Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
          <li>Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt-out by [checking the relevant box located on the form on which we collect your data (the [order form/registration form])/OTHER OPT-OUT METHOD]. You can also always opt-out by logging into the Website and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to websitesessentialhealthinfo.com</li>
          <li>Promotional Offers from the Company. If you do not wish to have your email address and contact information used by EssentialHealthInfo, Inc. to promote our own or third-parties' products or services, you can opt-out by sending an email to websitesessentialhealthinfo.com or at any other time by logging into our website(s) and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to websitesessentialhealthinfo.com. If we have sent you a promotional email, you may send us a return email asking to be omitted from future email distributions. [This opt out does not apply to information provided to EssentialHealthInfo, Inc. as a result of a product purchase, warranty registration, product service experience or other transactions.]]</li>
          <li>Targeted Advertising. If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers' target-audience preferences, you can opt-out by sending an email to websitesessentialhealthinfo.com or at any other time by logging into our website(s) and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to websitesessentialhealthinfo.com. (For this opt-out to function, you must have your browser set to accept browser cookies.)</li>     
        </ul>
        <h3 class="text-1xl mb-2 text-gray-500 ">
          We do not control third-parties' collection or use of your information to serve interest-based advertising. However, these third-parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.         
        </h3>
        <h3 class="text-1xl mb-4 text-black text-bold">
          Data Security
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. [All information you provide to us is stored on our secure servers behind firewalls.]
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. [We urge you to be careful about giving out information in public areas of our website(s) like message boards, comment sections, and forum areas. The information you share in public areas may be viewed by any user of our website(s).]         
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We are committed to ensuring that our suppliers have appropriate technical, administrative and physical procedures in place to ensure that your information is protected against loss or misuse. All information you provide to us is stored on our secure servers or on secure servers operated by a third party.            
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          [Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Website.]
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          If you chose to send us information via email, we cannot guarantee the security of this information. Once we have received your information, we will use strict procedures and security features to ensure it is dealt with in accordance with this Privacy Notice.
        </h3>
        <h3 class="text-2xl mt-5 mb-2 text-black text-bold">
          Your Rights           
        </h3>
        <h3 class="text-2xl mb-2 text-gray-500 text-bold">
          Changing Your Information
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          You have the right at any time to request that we change or amend the information that we hold about you, withdraw from any offer, competition or service or ask us not to contact you with any further offers, competitions or services. You can exercise your rights by checking certain boxes on the forms we use to collect your information or by amending your details by logging into your account. You can also request that we change your information by emailing us at
        </h3>
        <h3 class="text-2xl mb-2 text-blue-500 text-bold">
          California Rights
        </h3>
        <h3 class="text-2xl mb-2 text-gray-500 ">
          California Civil Code Section § 1798.83 permits users of our Website that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to websitesessentialhealthinfo.com
        </h3>
        <h3 class="text-2xl mb-2 text-gray-500 ">
          If you are a resident of the State of California and would like to learn how your “personal information” (as defined in the Shine the Light Law, Cal. Civ. Code § 1798.83) is shared with third-parties, or what categories of personal information that we have shared with third-parties in the preceding year, as well as the names and contact information of those third-parties, please contact us and we will get in touch.
        </h3>
        <h3 class="text-2xl mb-2 text-gray-500 ">
          If you are a resident of the State of California and would like to opt-out from the disclosure of your personal information to any third-party for marketing purposes, you can e-mail us at: websitesessentialhealthinfo.com; or send us mail to:
        </h3>
      </div>
    </div>
  </div>
<!-- About Us -->

<!-- Awards Banner -->

<!-- what coustomers are saying -->
</template>

<script>
import {EventBus} from "@/eventbus";
//import Storage from "@/storage";



export default {
  name: "DysonCordless",
  data() {
    return {

    };
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('app.screenChanged','pages');
    },1);
  },
}
</script>

<style scoped>


</style>

<template>
  <div>
    <div
      class="py-20"
      style="background: linear-gradient(90deg, #667eea 0%, #764ba2 100%)"
    >
      <div class="container mx-auto px-6 text-center">
        <h2 class="text-6xl font-bold mb-2 text-white">
          EssentialHealthInfo
        </h2>
        <h3 class="text-2xl mb-8 text-gray-200">
          Terms and Conditions
        </h3>
        <a href="http://app.essentialhealthinfo.com/"><button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
          Go Back 
        </button></a>
      </div>
    </div>
    <div
      class="py-20"
      style="background: white;"
    >
      <div class="container mx-auto  pt-3 pl-6 pr-6 pb-6">
        <h2 class="text-4xl font-bold mb-10 text-black">
          Terms and Conditions
        </h2>

        <h3 class="text-1xl mb-8 text-gray-500">
          Information About Us
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We are EssentialHealthInfo, ., a company registered in California. We own and operate the below websites.
        </h3>
        <ul class="list-disc ml-8 text-blue-500 underline  list-inside mb-4 bg-rose-200 ...">
          <li><a href="EssentialHealthInfo.com">EssentialHealthInfo</a></li>
        </ul>

        <h3 class="text-1xl mb-2 text-gray-500">
          This policy applies to information we collect:
        </h3>
        <ul class=" ml-8  mb-5 list-inside bg-rose-200 ...">
          <li>EssentialHealthInfo, Inc.</li>
          <li>Attn: Terms & Conditions Request</li>
          <li>23 Veneto Ln.,</li>
          <li>Aliso Viejo,</li>
          <li>CA 92656</li>
        </ul>

        <h3 class="text-1xl mb-2 text-black">
          Terms Of Website Use
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          Your access to our website is subject to our terms and conditions and we therefore encourage you to read through these terms. By using our website, you confirm that you accept these terms of use and that you agree to comply with them.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          In addition to these terms we also have other documents containing important information which applies to your use of our website:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>Our <a ref="">Privacy Policy,</a> sets out to explain how we process any personal data we collect and hold about you.</li>
          <li>Our <a ref="">Cookie Policy</a> gives you the details of the cookies that we use on our website, how they work and what information they collect.</li>
          <li>Our <a ref="">Acceptable Use Policy</a> sets out to explain how you can and cannot use our website, including the use of our interactive features.</li>
        </ul>
        <li />

        <h3 class="text-1xl mb-2 text-black">
          Changes To These Terms
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We may revise these terms at any time by amending this page. It is advisable that you check this page from time to time.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Changes To Our Website(s)
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>We will update our website and change the content from time to time. We do not notify users in advance.</li>
          <li>We always do our best to ensure that the information is accurate, however, we cannot guarantee that the website(s) or their content will be free from errors or omissions. We are not obligated to update any information on our website.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black">
          Your Access To Our Website(s)
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>Access to our home page and some of the information on our website is made available to all visitors. Please note that some of the services on our website may require visitor registration.</li>
          <li>Due to the nature of technology and the internet, we cannot promise that our website or any content on it will always be available or be uninterrupted. We are not obliged to fix or support our website.</li>
          <li>Your access to our website is permitted on a temporary basis and we may suspend, withdraw, discontinue or change all or any part of our website without notice. We will not be liable to you if our website is unavailable at any time or for any period.</li>
          <li>You are responsible for making all arrangements necessary for you to have access to our website and for ensuring that any person who accesses our website through your internet connection complies with these terms.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black">
          Your Account And Password
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          If you choose, or if you are provided with, a user identification code, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party. We have the right to disable any user identification code or password, at any time, without notice, if, in our reasonable opinion, you have failed to comply with any of the provisions of these terms. If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us by emailing us at websites@EssentialHealthInfo.com
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Who Owns The Content On Our Website(s)?
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>We own (or license) the copyright and other rights in our website and in the material published on it which is protected under international laws. All such rights are reserved</li>
          <li>You may make print outs or download extracts from our website for your use including showing others within your organization. However, you must not modify any copies of any materials you have printed off or downloaded in any way and must acknowledge our ownership.</li>
          <li>If you print off, copy or download any part of our website in breach of these terms of use, your right to use our website will cease immediately and you must return or destroy any copies of the materials you have made if requested.</li>
        </ul>
        <h3 class="text-1xl mb-2 text-black">
          Accuracy Of Website Content
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          The content on our website is provided for general information purposes only. It is not intended to amount to advice on which you should rely. You must obtain your own advice before taking (or not taking) any action on the basis of the content on our website. Although we make reasonable efforts to update the information on our website, we make no guarantee (whether expressed or implied) that the content on our website is accurate, complete or up-to-date.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Limitation Of Our Liability
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation, or any other liability that cannot be excluded or limited by English law. To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our website or any content on it, whether expressed or implied. We will not be liable to any user for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
        </h3>
        <ul class="list-disc ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>use of, or inability to use, our website; or</li>
          <li>use of or reliance on any content displayed on our website.</li>
        </ul>
        <h3 class="text-1xl mb-0 text-gray-500">
          We will have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
          We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our website or to your downloading of any content on it, or on any website linked to it.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Viruses
        </h3>
        <h3 class="text-1xl mb-5 text-gray-500">
          Whilst we take reasonable steps to ensure that our website is reliable and provides a fast service, we do not guarantee that our website will free from bugs or viruses. You are responsible for your ensuring your information technology, computer programmers and platform are set up correctly in order to access our website and you should use your own virus protection software.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          You must not misuse our website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorized access to our website, the server on which our website is stored or any server, computer or database connected to our website. You must not attack our website via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our website will cease immediately.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Linking To Our Website(s)
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          You must not establish a link to our website in any website that is not owned by you.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We reserve the right to withdraw linking permission without notice.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          The website in which you are linking must comply in all respects with the content standards as notified to you from time to time.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          If you wish to make any use of content on our website other than that set out above, please contact us by emailing us at websites@essentialhealthinfo.com.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Third Party Links & Resources On Our Website(s)
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          Where our website contains links to other websites and resources provided by third parties, these links are provided for your information only.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          We have no control over the contents of those websites or resources.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          When You Must Stop Using Our Website(s)
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          Things change, we may have to go our separate ways and immediately end our agreement and your use of our website.
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          If at any time you do not feel that you can agree to our terms or you are not happy with our website, you must stop using the it. We may also end your use of our website if you fail to use the it in the way it was intended, or ways in which are not compliant with our terms, against the spirit or intent of our website or to breach any law or regulation
        </h3>
        <h3 class="text-1xl mb-2 text-gray-500">
          If we do end your use of our website, we will tell you and you must immediately stop using the it. We will not offer you compensation for any losses at the time we end your use of the website.
        </h3>
        <h3 class="text-1xl mb-2 text-black">
          Enjoy Our Websites!
        </h3>
      </div>
    </div>
  </div>
<!-- About Us -->

<!-- Awards Banner -->

<!-- what coustomers are saying -->
</template>

<script>
import {EventBus} from "@/eventbus";
//import Storage from "@/storage";



export default {
  name: "DysonCordless",
  data() {
    return {

    };
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('app.screenChanged','pages');
    },1);
  },
}
</script>

<style scoped>


</style>

<template>
  <div>
    <div
      class="py-20"
      style="background: linear-gradient(90deg, #667eea 0%, #764ba2 100%)"
    >
      <div class="container mx-auto px-6 text-center">
        <h2 class="text-6xl font-bold mb-2 text-white">
          EssentialHealthInfo
        </h2>
        <h3 class="text-2xl mb-8 text-gray-200">
          Notice of Right to Opt Out of “Sale”
        </h3>
        <a href="http://app.essentialhealthinfo.com/"><button class="bg-white font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider">
          Go Back 
        </button></a>
      </div>
    </div>
    <div
      class="py-20"
      style="background: white;"
    >
      <div class="container mx-auto  pt-3 pl-6 pr-6 pb-6">
        <h2 class="text-2xl font-bold mb-10 text-black">
          Notice of Right to Opt Out of “Sale”
        </h2>
        <h3 class="text-1xl mb-8 text-gray-500">
          EssentialHealthInfo, respects your privacy. Your trust is important to us and we are committed to providing you with meaningful choices with respect to the information we collect from or about you. Beginning January 1, 2020, the California Consumer Privacy Act of 2018 (the “CCPA”) provides California residents with certain rights with respect to their personal information, including the right to opt out of certain disclosures of personal information as explained below.
        </h3>
        <ul class="list-decimal ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>Our websites collect information from or about you, including personal information, when you use the website services as described in our Privacy Policy. If you are a registered user of the EssentialHealthInfo( the “website” or “websites”) services, we may disclose certain information we collect about you for purposes that may be considered a “sale” under the CCPA. For example, we may disclose information to advertising partners, advertising technology companies, and companies that perform advertising-related services in order to provide you with more relevant advertising tailored to your interests on the website services. We may also disclose to our content programmers information about you, which may help personalize your experience and the content and ads you see on websites as well as other platforms and services, as further described in our Privacy Policy.</li>
          <li>Under the CCPA, you have the right to opt out of the “sale” of your personal information. We provide this right, subject to a verifiable consumer request, to account holders regarding personal information collected by us in connection with your account, including any profiles. To exercise this right, please send us an email to dpo@EssentialHealthInfo.com.</li>
          <li>You may designate an authorized agent to make a request on your behalf. Please click here to make a request as an authorized agent.</li>
          <li>If you are not a registered user of the website services, we have limited information about you and do not “sell” your information as described above. However, you have choices regarding how third parties may collect and use data about you from the website services as described below.</li>
          <li>Please note that this opt-out will apply on a going forward basis with respect to our disclosure of information. Even if you opt out, you will continue to receive ads on our websites, which may not be as tailored to your interests.</li>
        </ul>
        <h3 class="text-1xl mb-8 text-gray-500">
          In addition to providing you the ability to opt out of the “sale” of your personal information under CCPA as described above, you have additional controls and choices with respect to the collection and use of your information by third parties:
        </h3>
        <ul class="list-decimal ml-8 text-gray-500 list-inside mb-4 bg-rose-200 ...">
          <li>Some of the third parties that collect information from or about you on the website services in order to provide more relevant advertising to you participate in the Digital Advertising Alliance (“DAA”) Self-Regulatory Program for Online Behavioral Advertising and/or may be members of the Network Advertising Initiative (“NAI”). To make choices about the use of information from or about you for online behavioral or interest-based advertising on websites (desktop and mobile browsers), please visit the DAA and the NAI.</li>
          <li>To make choices about online behavioral or interest-based advertising within mobile applications, you can download the DAA’s AppChoices application from your mobile device at www.aboutads.info/appchoices or from your device’s app store.</li>
          <li>For more information about these and additional controls and choices available to you, please see Section “Information We Collect Through Automatic Data Collection Technologies” of our Privacy Policy.</li>
        </ul>
            
        <h3 class="text-1xl mb-2 text-gray-500">
          If you have any questions regarding EssentialHealthInfo’s data sharing practices, please feel free to email us at dpo@essentialhealthinfo.com.
        </h3>
      </div>
    </div>
  </div>
<!-- About Us -->

<!-- Awards Banner -->

<!-- what coustomers are saying -->
</template>

<script>
import {EventBus} from "@/eventbus";
//import Storage from "@/storage";



export default {
  name: "DysonCordless",
  data() {
    return {

    };
  },
  mounted() {
    setTimeout(() => {
      EventBus.$emit('app.screenChanged','pages');
    },1);
  },
}
</script>

<style scoped>


</style>
